import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/consents",
    name: "Consents",
    meta: { title: "Soglasja" },
    component: () => import(/* webpackChunkName: "Consents List" */ "@/Modules/Consent/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/consents/:id",
    name: "Consent Details",
    meta: { title: "Soglasje" },
    component: () => import(/* webpackChunkName: "Consent Details" */ "@/Modules/Consent/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/consents/unsigned/:id",
    name: "Unsigned Consent",
    meta: { title: "Soglasje" },
    component: () => import(/* webpackChunkName: "Consent Details" */ "@/Modules/Consent/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
