export default {
  confirm: "Confirm",
  cancel: "Prekliči",
  close: "Close",
  add: "Add",
  save: "Save",
  remove: "Remove",
  delete: "Izbriši",
  generate: "Generiraj",
  show_details: "Prikaži podrobnosti",
  open: "Odpri",
  edit: "Uredi",
  continue: "Nadaljuj",
  activate: "Aktiviraj",
  deactivate: "Deaktiviraj",
  components: {
    contact_info: {
      address: {
        title: "Address",
        remove_button: "Remove address",
        add_button: "Add address",
        address: "Address",
        postal: "Postal",
        city: "City",
        country: "Country"
      },
      contact: {
        title: "Kontakt",
        remove_button: "Odstrani kontakt",
        add_button: "Dodaj kontakt",
        contact: "Kontakt",
        phone: "Telefonska številka",
        email: "Elektronski naslov",
        any: "Kontakt",
        type: "Tip",
        label: "Ime in priimek"
      }
    },
    multiple_entries_check: {
      label: "Želite vpisati še eno?",
      description: "Po potrditvi bo forma ostala odprta"
    },
    cookies: {
      title: "Tudi mi pečemo piškotke",
      button: "Skrij obvestilo",
      text: "Spletna stran uporablja piškotke za zagotavljanje čim prijetnejše izkušnje. Uporablja samo piškotke, nujne za delovanje strani in ne za namene analitike ter marketinga. Z nadaljevanjem se strinjate z uporabo piškotkov."
    }
  },
  navigation: {
    dashboard: "Pregled",
    questionnaires: "Vprašalniki",
    consents: "Soglasja",
    jobs: "Prosta delovna mesta",
    settings: "Urejanje profila",
    logout: "Odjava"
  },
  static_data: {
    career_level: {
      "beginner": "Začetnik",
      "experienced-worker": "Izkušeni delavec",
      "experienced-expert": "Izkušeni strokovnjak",
      "middle-management": "Srednji management",
      "top-management": "Top management"
    },
    education: {
      "1": "I. Osnovna šola – nedokončana",
      "2": "II. Osnovna šola – dokončana",
      "3": "III. Nižje poklicno izobraževanje",
      "4": "IV. Srednje poklicno izobraževanje",
      "5": "V. Gimnazijsko izobraževanje",
      "6": "VI. Višješolski programi",
      "7": "VII. Univerzitetni programi",
      "8": "VIII. Magisteriji stroke",
      "9": "IX. Magisterij znanosti",
      "10": "X. Doktorati znanosti"
    }
  }
}
