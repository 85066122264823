export default {
  views: {
    list: {
      view_button: "Ogled soglasja",
      empty_list_notice: "Vaš seznam soglasij je prazen."
    },
    details: {
      header1: "Soglasje",
      header2: "Za nadaljevanje je potrebno soglasje",
      back_button: "Nazaj na seznam soglasij",
      confirm_button: "Potrdi soglasje"
    }
  }
}
