export default {
  views: {
    list: {
      view_button: "View consent",
      empty_list_notice: "Your consent list is empty."
    },
    details: {
      header1: "Consent",
      header2: "If you want to continue we need your consent",
      back_button: "Back to the list of consents",
      confirm_button: "Confirm consent"
    }
  }
}
