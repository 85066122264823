export default {
  auth: {
    login: {
      headline: "Competo Portal",
      headline_desc: "Login",
      forgot_password: "Did you forget your password?",
      forgot_password_description: "Not a problem. We can reset your password.",
      forgot_password_button: "Reset password",
      create_account: "No account?",
      create_account_description: "You can create new account.",
      create_account_button: "Create new account",
      remember_me: "Remember me",
      login: "Login",
      fields: {
        username: {
          label: "User name",
          placeholder: "name.lastname"
        },
        password: {
          label: "Password"
        }
      }
    },
    forgot_password: {
      headline: "Password reset",
      headline_desc: "Check your inbox. You will recieve a link to password resetting to your email.",
      submit: "Send e-mail",
      fields: {
        email: {
          label: "E-mail",
          placeholder: "user@competo.si"
        }
      },
      remember_credentials: {
        headline: "Do you remember your login data?",
        headline_desc: "Return to login page form and complete login with correct combination of username and password.",
        button: "Back to login"
      },
      message: {
        "1": "We have sent you an e-mail with a link to password reset.",
        "2": "Email should be in your inbox shortly.",
        "3": "Click to resend, if email did not show up in your inbox."
      }
    },
    reset_password: {
      headline: "Password reset",
      headline_desc: "You will recieve a link to password resetting to your email",
      remember_credentials: {
        headline: "Do you remember your login data?",
        headline_desc: "Return to page with a login form and complete login with correct combination of username and password.",
        button: "Back to login"
      },
      submit: "Password reset",
      fields: {
        email: {
          label: "e-mail",
          placeholder: "user@competo.si"
        },
        password: {
          label: "New password"
        }
      },
      error_message: "Password must contain at least 6 characters, one letter, number and special character (!@#%^&*?-=+_)",
      notify: {
        success: "Your password change was successful!",
        error: "Error reseting your password."
      }
    }
  }
}
