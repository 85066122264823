import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/jobs",
    name: "Jobs",
    meta: { title: "Delovna mesta" },
    component: () => import(/* webpackChunkName: "Questionnaires List" */ "@/Modules/Job/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/jobs/:id",
    name: "Job Details",
    meta: { title: "Delovno mesto" },
    component: () => import(/* webpackChunkName: "Consent Details" */ "@/Modules/Job/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
