export default {
  views: {
    list: {
      headline: "Vaša opravila",
      empty_list_notice: "Odlično. Vaš seznam opravil je prazen.",
      data: {
        headline: "Podatki",
        headline_desc: "Prenos podatkov o opravljenih psiholoških testiranjih. Prenos ponavadi traja 15-20 sekund.",
        download: "Prenos podatkov"
      }
    },
    basic: {
      option1: "Želimo vam upešen dan!",
      option2: "Pozdravljeni v Portalu!"
    }
  }
}
