export default {
  views: {
    list: {
      header: {
        title: "Media",
        search_placeholder: "Search ...",
        add_button: "Add media"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    details: {
      header: {
        back_button: "Media list",
        edit_button: "Edit media",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} media here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    }
  },
  popup: {
    title: "New Media",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add media",
    update_button: "Update media"
  },
  prompts: {
    delete: {
      title: "Delete {name}",
      message: "You're about to delete {name}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "File {name} created",
    updated: "File {name} updated",
    deleted: "File {name} was deleted",
    not_found: "Unable to find specified file"
  },
  widget: {
    title: "Files",
    title_cv: "CV and files",
    upload: "Upload file",
    generate: "Generate from template",
    table: {
      title: "Title",
      collection: "Collection",
      remove_button: "Delete"
    }
  },
  preview: {
    back: "Back",
    close: "Close",
    loading_document: "Loading document",
    prompt: {
      close: {
        title: "Document has not been saved",
        message: "Document has not been saved to the application. If you close this popup, document will be discarded.",
        confirm: "Discard document",
        cancel: "Cancel"
      }
    },
    download: {
      headline: "Download document",
      headline_desc: "Download the file to your device.",
      download_pdf: "Download preview (PDF)",
      download_word: "Download file (Word)",
      download_file: "Download document"
    }
  }
}
