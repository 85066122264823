// import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  // {
  //   path: "/media",
  //   name: "Media",
  //   meta: { title: "Media" },
  //   component: () => import(/* webpackChunkName: "Media List" */ "@/Modules/Media/Views/List"),
  //   children: [
  //     {
  //       path: "new",
  //       name: "New Media",
  //       meta: { title: "New Media" }
  //     },
  //     {
  //       path: "edit/:id",
  //       name: "Edit Media (List)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: "/media/details/:id",
  //   name: "Media Details",
  //   component: () => import(/* webpackChunkName: "Media Details" */ "@/Modules/Media/Views/Details"),
  //   children: [
  //     {
  //       path: "edit",
  //       name: "Edit Media (Details)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // }
]
