export default {
  confirm: "Confirm",
  cancel: "Cancel",
  close: "Close",
  add: "Add",
  save: "Save",
  remove: "Remove",
  delete: "Delete",
  generate: "Generate",
  show_details: "Show details",
  open: "Open",
  edit: "Edit",
  continue: "Continue",
  activate: "Activate",
  deactivate: "Deactivate",
  components: {
    contact_info: {
      address: {
        title: "Address",
        remove_button: "Remove address",
        add_button: "Add address",
        address: "Address",
        postal: "Postal",
        city: "City",
        country: "Country"
      },
      contact: {
        title: "Contact",
        remove_button: "Remove contact",
        add_button: "Add contact",
        contact: "Contact",
        phone: "Phone",
        email: "E-mail",
        any: "Contact",
        type: "Type",
        label: "First and last name"
      }
    },
    multiple_entries_check: {
      label: "Would you like to enter another one?",
      description: "The form will remain open even after pressing confirm button"
    },
    cookies: {
      title: "We bake cookies too",
      button: "I understand",
      text: "This website uses cookies to provide the best experience. It only uses cookies that are necessary for the site to function and not for analytics and marketing purposes. By continuing you agree to the usage of cookies."
    }
  },
  navigation: {
    dashboard: "Dashboard",
    questionnaires: "Questionnaires",
    consents: "Consents",
    jobs: "Jobs",
    settings: "Profile editing",
    logout: "Logout"
  },
  static_data: {
    career_level: {
      "beginner": "Beginner",
      "experienced-worker": "Experienced worker",
      "experienced-expert": "Experienced expert",
      "middle-management": "Middle management",
      "top-management": "Top management"
    },
    education: {
      "1": "I. Primary school – unfinished",
      "2": "II. Primary school – completed",
      "3": "III. Lower vocational education",
      "4": "IV. High school level vocational education ",
      "5": "V. High school education",
      "6": "VI. Higher education programs",
      "7": "VII. University degree programs",
      "8": "VIII. Master's degree",
      "9": "IX. Researched based Master's degree",
      "10": "X. Ph.D"
    }
  }
}
