import LaravelResourceRepository from "@/providers/api/repositories/LaravelResourceRepository"
import Vue from "vue"

function removeExistingCodelistKeysFromParams (params, codelists) {
  let loadedCodelists = Object.keys(codelists)
  if (typeof params === "string" && loadedCodelists.includes(params)) return ""
  // If params aren't a string they should be an array
  params.forEach((e, key) => {
    let currentCodelistLoaded = loadedCodelists.includes(e)
    if (currentCodelistLoaded) params.splice(params.indexOf(e), 1)
  })
  return params
}

export default class Codelist extends LaravelResourceRepository {
  route = "codelists"
  namespace = "codelists"

  state = {
    multipleLoadersTypes: ["updateData", "removeData"],
    loaders: {
      list: false,
      details: false,
      store: false,
      update: [],
      remove: [],
      storeData: false,
      updateData: [],
      removeData: []
    },
    // Full codelist namespaced by schema slug (schema object)
    codelists: {},
    // Codelist data namespaced by schema slug (array of objects)
    options: {},
    // Codelist data namespaced by schema slug and data id (object with number key and object value)
    optionsById: {},
    // Codelist data namespaced by schema slug and data key (object with string key and object value)
    optionsByKey: {},
    // Codelist schema key to id map
    schemaIds: {},
    // Codelist schema id to key map
    schemaKeys: {}
  }

  actions = {
    /**
   * Gets a single or multiple codelists and their data.
   * If a codelist is already loaded, it won't be refetched
   * unless the overwrite param is set to true
   *
   * @param {number|array} params - A single or multiple slugs of wanted codelists
   * @param {boolean} overwrite - Should the loaded codelists be overwriten
   *
   * @returns {object} Response status and data
   */
    get: async ({ state, commit }, { params, overwrite = false }) => {
      if (!overwrite) params = removeExistingCodelistKeysFromParams(params, state.codelists)
      if (params.length === 0) return

      let response = await this.request({ namespace: "get" }).get("/codelists/get/" + params)
      if (Array.isArray(params)) {
        params.forEach(param => {
          commit("SET_CODELIST_OPTIONS", { slug: param, codelist: response.data[param] })
        })
      } else {
        commit("SET_CODELIST_OPTIONS", { slug: params, codelist: response.data })
      }

      return response
    },
    /**
     * Deletes a codelistSchema
     *
     * @param {number} id - Id of the schema we wish to delete
     *
     * @returns {object} Response status and data
     */
    remove: async ({ state, commit }, id) => {
      let response = await this.request({ namespace: "get" }).delete("/codelists/" + id)
      if (response.isError) return response

      commit("REMOVE_CODELIST_OPTIONS", { id })
    },
    /**
     * Updates a codelist data record
     *
     * @param {number} schema_slug - Slug of the codelist schema
     * @param {number} data_id - Id of the codelist data
     * @param {object} params - Params we wish to update the record with
     *
     * @returns {object} Response status and data
     */
    // eslint-disable-next-line camelcase
    updateData: async (ctx, { schema_slug, data_id, params }) => {
      let response = await this.request({ namespace: "updateData" }).put("/codelists/updateData", { schema_slug, data_id, params })
      return response
    },
    /**
     * Stores a codelist data record
     *
     * @param {number} schema_slug - Slug of the codelist schema
     * @param {object} params - Params we wish to store
     *
     * @returns {object} Response status and data
     */
    // eslint-disable-next-line camelcase
    storeData: async (ctx, { schema_slug, params }) => {
      let response = await this.request({ namespace: "updateData" }).post("/codelists/storeData", { schema_slug, params })
      return response
    },
    /**
     * Removes a codelist data record
     *
     * @param {number} schema_slug - Slug of the codelist schema
     * @param {number} data_id - Id of the codelist data
     *
     * @returns {object} Response status and data
     */
    // eslint-disable-next-line camelcase
    removeData: async (ctx, { schema_slug, data_id }) => {
      let response = await this.request({ namespace: "removeData" }).post("/codelists/removeData", { schema_slug, data_id })
      return response
    },

    /**
     *
     * CUSTOM FEATURES
     *
     *
     */

    getCandidateCodelists: async ({ state, commit }, { params, overwrite = false }) => {
      if (!overwrite) params = removeExistingCodelistKeysFromParams(params, state.codelists)
      if (params.length === 0) return

      let response = await this.request({ namespace: "getCandidateCodelists" }).get("portal/candidate/get-candidate-codelists/" + params)
      if (Array.isArray(params)) {
        params.forEach(param => {
          commit("SET_CODELIST_OPTIONS", { slug: param, codelist: response.data[param] })
        })
      } else {
        commit("SET_CODELIST_OPTIONS", { slug: params, codelist: response.data })
      }

      return response
    }
  }

  mutations = {
    /**
     * Persists provided codelist in the state
     * Persists full codelist namespaced by schema slug (schema object)
     * Persists codelist data namespaced by schema slug (array of objects)
     * Persists codelist data namespaced by schema slug and data id (object with number key and object value)
     * Persists codelist data namespaced by schema slug and data key (object with string key and object value)
     * Persists codelist schema id to key map
     * Persists codelist schema key to id map
     *
     * @param {number} slug - Slug of the codelist schema
     * @param {number} codelist - Codelist schema with data
     */
    SET_CODELIST_OPTIONS (state, { slug, codelist }) {
      Vue.set(state.codelists, slug, codelist)
      Vue.set(state.options, slug, codelist.data)
      Vue.set(state.optionsById, slug, {})
      Vue.set(state.optionsByKey, slug, {})
      codelist.data.forEach(value => {
        state.optionsById[slug][value.id] = value
        state.optionsByKey[slug][value.key] = value
      })
      Vue.set(state.schemaIds, slug, codelist.id)
      Vue.set(state.schemaKeys, codelist.id, slug)
    },
    /**
     * Removes one or multiple codelists from the state
     * An id OR slug or aray of those must be provided
     *
     * @param {number|array} id - An id or array of ids of codelists to be removed
     * @param {string|array} slug - A slug or array of slugs of codelists to be removed
     */
    REMOVE_CODELIST_OPTIONS (state, { id, slug }) {
      let removeCodelists = (id, slug) => {
        Vue.delete(state.optionsByKey, slug)
        Vue.delete(state.optionsById, id)
        Vue.delete(state.codelists, slug)
        Vue.delete(state.schemaIds, slug, id)
        Vue.delete(state.schemaKeys, id, slug)
      }
      if (id) {
        slug = state.codelists.find(e => e.id === id)
        if (!Array.isArray(id)) {
          removeCodelists(id, slug)
        } else {
          id.forEach(item => {
            slug = state.codelists.find(e => e.id === item)
            removeCodelists(id, slug)
          })
        }
      } else if (slug) {
        if (!Array.isArray(slug)) {
          id = state.codelists.find(e => e.slug === slug)
          removeCodelists(id, slug)
        } else {
          slug.forEach(item => {
            id = state.codelists.find(e => e.slug === item)
            removeCodelists(id, slug)
          })
        }
      }
    }
  }
}
