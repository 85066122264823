import { ifNotAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"
export default [
  {
    path: "/login",
    name: "Login",
    meta: { title: "Prijava" },
    component: () => import(/* webpackChunkName: "Auth" */ "@/Modules/Auth/Auth/Views/Login.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/login/forgot-password",
    name: "Forgot password",
    meta: { title: "Ponastavitev gesla" },
    component: () => import(/* webpackChunkName: "Auth" */ "@/Modules/Auth/Auth/Views/ForgotPassword.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/login/reset-password",
    name: "Reset password",
    meta: { title: "Ponastavitev gesla" },
    component: () => import(/* webpackChunkName: "Auth" */ "@/Modules/Auth/Auth/Views/ResetPassword.vue"),
    beforeEnter: ifNotAuthenticated
  }
]
