export default {
  views: {
    details: {
      candidate_settings: {
        headline: {
          basic: "Osebni podatki",
          other: "Ostali podatki"
        },
        headline_description: "Uporabniški podatki",
        headline_description_2: "Ostali podatki vezani na uporabnika",
        description: {
          basic: "Osnovni podatki",
          current_employment: "Podatki o trenutni zaposlitvi",
          wanted_employment: "Podatki o želeni zaposlitvi"
        },
        fields: {
          name: {
            label: "Ime in priimek"
          },
          gender: {
            label: "Spol"
          },
          education: {
            label: "Stopnja izobrazbe"
          },
          birthday: {
            label: "Datum rojstva"
          },
          career: {
            label: "Trenutni karierni nivo"
          },
          email: {
            label: "Elektronski naslov",
            placeholder: "info@email.com"
          },
          address: {
            label: "Ulica in hišna številka"
          },
          postal: {
            label: "Poštna številka"
          },
          city: {
            label: "Mesto"
          },
          country: {
            label: "Država"
          },
          phone: {
            label: "Telefonska številka"
          },
          position_title: {
            label: "Naziv pozicije"
          },
          position_department: {
            label: "Naziv oddelka"
          },
          username: {
            label: "Uporabniško ime"
          },
          linkedin_url: {
            label: "LinkedIn povezava"
          },
          employer: {
            label: "Trenutni delodajalec"
          },
          industries: {
            label: "Industrija"
          },
          workfields: {
            label: "Področje dela"
          },
          expectations: {
            label: "Želena letna bruto plača (EUR)"
          },
          region: {
            label: "Regija"
          },
          phone_candidate: {
            label: "Telefonska številka"
          },
          position_title_candidate: {
            label: "Naziv delovnega mesta"
          },
          education_summary: {
            label: "Dosežen naziv in izobraževalna ustanova"
          },
          notice_days: {
            label: "Odpovedni rok (št. dnevov)"
          }
        },
        gender: {
          male: "Moški",
          female: "Ženska"
        },
        button: {
          save: "Shrani podatke"
        }
      },
      reset_password: {
        title: "Sprememba gesla",
        subtitle: "Varnost in prijava",
        form: {
          currentPassword: "Trenutno geslo",
          newPassword: "Novo geslo",
          changePassword: "Spremeni geslo",
          passwordRule: "Geslo mora vsebovati najmanj 6 znakov, vsaj eno črko, številko in poseben znak (!@#%^&*?-=+_)"
        },
        feedback: {
          succcess: "Vaše geslo je bilo uspešno spremenjeno!",
          changeItAgain: "Ponovno spremeni?"
        }
      },
      language: {
        title: "Spremeni jezik"
      },
      tests: {
        headline: "Testiranja",
        headline_description: "Seznam vaših testiranj. Niste še sodelovali v testiranju.",
        table: {
          title: "Naziv testiranja",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      projects: {
        headline: "Projekti",
        headline_description: "Seznam vaših projektov. Niste še sodelovali v projektu.",
        table: {
          title: "Naziv projekta",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      }
    },
    user_info: {
      headline: "Pozdravljeni v Competo Portalu",
      headline_description: "Vaš profil je skoraj pripravljen",
      p1: "Ustvarili smo vaš uporabniški račun, le še nekaj podatkov nam manjka.",
      p2: "Preden nadaljujete vas prosimo, da navedete manjkajoče podatke in preverite verodostojnost obstojočih.",
      p3: "Podatke lahko kadarkoli spremenite v vaših uporabniških nastavitvah."
    }
  }
}
