export default {
  views: {
    list: {
      headline: "Your to-do list",
      empty_list_notice: "Great. Your to-do list is empty.",
      data: {
        headline: "Data",
        headline_desc: "Download your data on psychological tests. Download can take up to 15-20 seconds.",
        download: "Downloading data"
      }
    },
    basic: {
      option1: "Have a successful day!",
      option2: "Welcome to the Portal!"
    }
  }
}
