export default {
  views: {
    list: {
      view_button: "Prikaži delovno mesto",
      id: "ID delovnega mesta",
      publish_date: "Datum objave",
      location: "Lokacija",
      worktime: "Vrsta",
      region: "Regija",
      applied: "Prijavljen",
      search_placeholder: "Iskanje po nazivu in lokaciji ...",
      no_info: "Ni podatka",
      all_regions: "Vse regije",
      all_worktimes: "Vse vrste",
      empty_list_notice: "Seznam delovnih mest je prazen. Spremenite iskalne kriterije ali preverite znova pozneje."
    },
    details: {
      back_button: "Nazaj na seznam delovnih mest",
      id: "ID delovnega mesta",
      publish_date: "Datum objave",
      location: "Lokacija",
      worktime: "Vrsta",
      region: "Regija",
      contact_person: "Kontaktna oseba",
      contact_email: "E-mail naslov",
      apply: "Prijavi se",
      applied: "Prijavljen",
      close: "Zapri",
      headlines: {
        offerings: "Kaj podjetje ponuja?",
        expectations: "Zaželena znanja, izkušnje in kompetence",
        company: "Predstavitev podjetja",
        description: "Izzivi, s katerimi se bo srečeval izbrani kandidat",
        about_us: "O nas"
      },
      about_us: "Smo svetovalno podjetje, ki se ukvarja z iskanjem in selekcijo, HR svetovanjem in razvojem. Verjamemo, da je ujemanje kandidata s kulturo podjetja izjemno pomembno za zadovoljstvo na obeh straneh. Zavedamo se, da samo življenjepis ni dovolj, zato pri kandidatih iščemo tisto pravo »iskrico« - samo tisti, ki svoje delo opravljajo s strastjo, lahko premikajo meje in ustvarjajo uspešne zgodbe. V Competu iščemo prav take: talente, ki bodo soustvarjali uspehe in podvige naših partnerjev.",
      apply_popup: {
        title: "Prijavi se",
        cancel: "Prekliči",
        confirm: "Potrdi",
        message: "Za prijavo na delovno mesto morate biti prijavljeni v uporabniški račun. Preusmerili vas bomo na domačo stran, kjer se boste lahko prijavili ali ustvarili nov račun."
      },
      apply_prompt: {
        title: "Prijava uspešna!",
        message: "Zahvaljujemo se za vašo prijavo."
      }
    }
  }
}
